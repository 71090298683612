var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useCallback, useEffect, useState } from 'react';
import { FormControl, Input, Grid, Checkbox, Select, IconButton, Option, Modal, ModalDialog, ModalClose, DialogTitle, DialogContent, Stack, Button, FormLabel } from '@mui/joy';
import DeleteIcon from '@mui/icons-material/Delete';
import TuneIcon from '@mui/icons-material/Tune';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';
function Variable(props) {
    var variable = props.variable, pluginIndex = props.pluginIndex, varIndex = props.varIndex, invalidFields = props.invalidFields, onPluginVariablePropertyChange = props.onPluginVariablePropertyChange, onPluginDeleteVariable = props.onPluginDeleteVariable, onPluginVariableAddEnumValue = props.onPluginVariableAddEnumValue, onPluginVariableUpdateEnumValue = props.onPluginVariableUpdateEnumValue, onPluginVariableDeleteEnumValue = props.onPluginVariableDeleteEnumValue;
    var _a = useState(false), isRegexModalOpen = _a[0], setIsRegexModalOpen = _a[1];
    var _b = useState(false), isEnumModalOpen = _b[0], setIsEnumModalOpen = _b[1];
    var _c = useState(''), formatRegex = _c[0], setFormatRegex = _c[1];
    var closeFormatRegexModal = useCallback(function () {
        setIsRegexModalOpen(false);
    }, []);
    var saveFormatRegex = useCallback(function () {
        onPluginVariablePropertyChange(pluginIndex, varIndex, 'formatRegex', formatRegex);
        setIsRegexModalOpen(false);
    }, [formatRegex, onPluginVariablePropertyChange, pluginIndex, varIndex]);
    var closeEnumModal = useCallback(function () {
        setIsEnumModalOpen(false);
    }, []);
    useEffect(function () {
        if (isRegexModalOpen) {
            setFormatRegex(variable.formatRegex);
        }
    }, [isRegexModalOpen, variable.formatRegex]);
    return (_jsxs(_Fragment, { children: [_jsx(Grid, __assign({ xs: 2 }, { children: _jsx(FormControl, __assign({ sx: { display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 } }, { children: _jsx(Input, { size: "sm", placeholder: "Name", error: invalidFields.some(function (e) { return e.field === "plugins[".concat(pluginIndex, "].variables[").concat(varIndex, "].name"); }), value: variable.name, onChange: function (event) {
                            return onPluginVariablePropertyChange(pluginIndex, varIndex, 'name', event.target.value);
                        } }) })) })), _jsx(Grid, __assign({ xs: 2 }, { children: _jsx(FormControl, __assign({ sx: { display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 } }, { children: _jsx(Input, { size: "sm", placeholder: "Label", error: invalidFields.some(function (e) { return e.field === "plugins[".concat(pluginIndex, "].variables[").concat(varIndex, "].label"); }), value: variable.label, onChange: function (event) {
                            return onPluginVariablePropertyChange(pluginIndex, varIndex, 'label', event.target.value);
                        } }) })) })), _jsx(Grid, __assign({ xs: 2 }, { children: _jsxs(FormControl, __assign({ sx: { display: 'flex', flexDirection: 'row', alignItems: 'center' } }, { children: [_jsxs(Select, __assign({ size: "sm", placeholder: "Type", value: variable.type, sx: { flexGrow: 1 }, onChange: function (event, newValue) {
                                return onPluginVariablePropertyChange(pluginIndex, varIndex, 'type', newValue);
                            } }, { children: [_jsx(Option, __assign({ value: "string" }, { children: "String" })), _jsx(Option, __assign({ value: "int32" }, { children: "Integer" })), _jsx(Option, __assign({ value: "decimal" }, { children: "Decimal" })), _jsx(Option, __assign({ value: "datetime" }, { children: "Date and Time" })), _jsx(Option, __assign({ value: "phonenumber" }, { children: "Phone Number" })), _jsx(Option, __assign({ value: "enum" }, { children: "Enum" }))] })), variable.type === "string" &&
                            _jsx(IconButton, __assign({ size: "sm", onClick: function () { return setIsRegexModalOpen(true); } }, { children: _jsx(TuneIcon, {}) })), variable.type === "enum" &&
                            _jsx(IconButton, __assign({ size: "sm", onClick: function () { return setIsEnumModalOpen(true); } }, { children: _jsx(TuneIcon, {}) }))] })) })), _jsx(Grid, __assign({ xs: 2 }, { children: _jsx(FormControl, __assign({ sx: { display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 } }, { children: _jsx(Input, { size: "sm", placeholder: "Question", value: variable.question, onChange: function (event) {
                            return onPluginVariablePropertyChange(pluginIndex, varIndex, 'question', event.target.value);
                        } }) })) })), _jsx(Grid, __assign({ xs: 1 }, { children: _jsx(FormControl, { children: _jsx(Checkbox, { size: "sm", sx: { display: 'flex', justifyContent: 'center' }, checked: variable.isDictationModeEnabled, onChange: function (event) {
                            return onPluginVariablePropertyChange(pluginIndex, varIndex, 'isDictationModeEnabled', event.target.checked);
                        } }) }) })), _jsx(Grid, __assign({ xs: 1 }, { children: _jsx(FormControl, { children: _jsx(Checkbox, { size: "sm", sx: { display: 'flex', justifyContent: 'center' }, checked: variable.shouldCheckSpelling, onChange: function (event) {
                            return onPluginVariablePropertyChange(pluginIndex, varIndex, 'shouldCheckSpelling', event.target.checked);
                        } }) }) })), _jsx(Grid, __assign({ xs: 1 }, { children: _jsx(FormControl, { children: _jsx(Checkbox, { size: "sm", sx: { display: 'flex', justifyContent: 'center' }, checked: variable.isRequired, onChange: function (event) {
                            return onPluginVariablePropertyChange(pluginIndex, varIndex, 'isRequired', event.target.checked);
                        } }) }) })), _jsx(Grid, __assign({ xs: 1 }, { children: _jsx(IconButton, __assign({ size: "sm", onClick: function () { return onPluginDeleteVariable(pluginIndex, varIndex); } }, { children: _jsx(DeleteIcon, {}) })) })), variable.type == "string" &&
                _jsx(Modal, __assign({ open: isRegexModalOpen, onClose: closeFormatRegexModal, hideBackdrop: true }, { children: _jsxs(ModalDialog, __assign({ variant: "outlined" }, { children: [_jsx(ModalClose, {}), _jsx(DialogTitle, { children: "RegEx Validation" }), _jsx(DialogContent, __assign({ sx: { py: 2 } }, { children: _jsxs(Stack, __assign({ spacing: 2 }, { children: [_jsx(Input, { autoFocus: true, value: formatRegex, onChange: function (event) { return setFormatRegex(event.target.value); } }), _jsx(Button, __assign({ variant: "soft", onClick: saveFormatRegex }, { children: "OK" }))] })) }))] })) })), variable.type == "enum" &&
                _jsx(Modal, __assign({ open: isEnumModalOpen, onClose: closeEnumModal, hideBackdrop: true }, { children: _jsxs(ModalDialog, __assign({ variant: "outlined" }, { children: [_jsx(ModalClose, {}), _jsx(DialogTitle, { children: "Enum Values" }), _jsxs(DialogContent, __assign({ sx: { py: 2 } }, { children: [Object.keys(variable.enumValues || []).length > 0 &&
                                        _jsxs(Grid, __assign({ spacing: 1, container: true }, { children: [_jsx(Grid, __assign({ xs: 5 }, { children: _jsx(FormLabel, { children: "Value" }) })), _jsx(Grid, __assign({ xs: 6 }, { children: _jsx(FormLabel, { children: "Label (Optional)" }) })), _jsx(Grid, { xs: 1 }), variable.enumValues.map(function (val, enumIndex) { return (_jsxs(React.Fragment, { children: [_jsx(Grid, __assign({ xs: 5 }, { children: _jsx(Input, { size: "sm", value: val.value, onChange: function (event) { return onPluginVariableUpdateEnumValue(pluginIndex, varIndex, enumIndex, event.target.value, "value"); } }) })), _jsx(Grid, __assign({ xs: 6 }, { children: _jsx(Input, { size: "sm", value: val.display, onChange: function (event) { return onPluginVariableUpdateEnumValue(pluginIndex, varIndex, enumIndex, event.target.value, "display"); } }) })), _jsx(Grid, __assign({ xs: 1 }, { children: _jsx(IconButton, __assign({ size: "sm", onClick: function () { return onPluginVariableDeleteEnumValue(pluginIndex, varIndex, enumIndex); } }, { children: _jsx(DeleteIcon, {}) })) }))] }, enumIndex)); })] })), _jsx(Stack, { children: _jsx(Button, __assign({ size: "sm", color: "primary", variant: "plain", startDecorator: _jsx(AddIcon, {}), onClick: function () { return onPluginVariableAddEnumValue(pluginIndex, varIndex); }, sx: { alignSelf: 'flex-start' } }, { children: "Add new pair" })) }), _jsx(Stack, __assign({ alignItems: "center" }, { children: _jsx(Button, __assign({ variant: "soft", onClick: closeEnumModal, sx: { width: '200px' } }, { children: "OK" })) }))] }))] })) }))] }));
}
export default memo(Variable);
